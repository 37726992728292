/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        //slicknav//

      jQuery('.menu-primary').slicknav({

        label:          '',
        prependTo:      'header .nav-primary',	

    });

      /*HEADER start*/

                var scroll = 0;
                
                // Header Scroll

                 if (jQuery(window).width() > 768) {

                    jQuery( window ).scroll(function() {
                    
                        var scrollPos = $(window).scrollTop();

                        var width=jQuery( window ).width();
                    
                    if ( scrollPos > 50 && width > 1200) {
                        
                        if ( scroll === 0 ) {

                            jQuery('header').addClass('header-saddow');

                            jQuery('header').animate({
                                
                                top: '-43px'

                            },300);

                            scroll++;
                            
                        }
                        
                    }
                    
                    if ( scrollPos === 0 && width > 1200) {

                        jQuery('header').removeClass('header-saddow');

                        jQuery('header').animate({
                                
                                top: '0px'

                            },300);
                    
                        scroll = 0;
                        
                    }

                });

            } 

          // Scroll Top
                
        jQuery(window).scroll(function() {

        var scroll = jQuery(window).scrollTop();

            if (scroll > 200) {

                jQuery(".scrolltop").fadeIn(500);

            } else {

                jQuery(".scrolltop").fadeOut(500);

            }
        });
    
        jQuery(".scrolltop").hide();

        jQuery('.scrolltop').click(function() {

            jQuery('html, body').animate({

                scrollTop: 0
                
            }, "slow");
        });


      /* iframe scroll*/

      jQuery('.iframe-section').click(function(){

			jQuery(this).find('iframe').addClass('clicked')}).mouseleave(function(){

			jQuery(this).find('iframe').removeClass('clicked')});


      // window.sr = ScrollReveal();

      // sr.reveal('.doctor-details');

        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        /* Main slider */

    jQuery('.slider').slick({

      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      autoplaySpeed: 3000,
      autoplay: true,
      prevArrow: '.button-prev',
      nextArrow: '.button-next'

    });

    // Testimonials
      
    jQuery('.testimonials').slick({ 
      
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 200,
      fade: true,
      cssEase: 'linear',
      prevArrow: '.btn-prev',
      nextArrow: '.btn-next',
      autoplay: true,
      autoplaySpeed: 3000,

    });

    

        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {

         //scroll icon

            jQuery(window).scroll(function() {

            // if (jQuery(".about-us").length > 0) {

                // jQuery("a.scroll-ease").mouseover(function(){

        // jQuery("span").css("opacity",1);

    // });

              var scrollPos = jQuery(window).scrollTop();

              var footer = (jQuery(".site-footer").offset().top) - 250;//$('div').offset().top

              var width = jQuery(window).width();

              if(scrollPos > 330 && scrollPos < footer && width > 768 ){

                jQuery(".about-icons").slideDown();

              }

              if(scrollPos < 330) {

                jQuery(".about-icons").slideUp();

              }

              if(scrollPos > footer) {

                jQuery(".about-icons").slideUp();

              }

            // }

          });

        jQuery('a.scroll-ease').click(function() {

            var tergrtVal = jQuery(this).attr('href');

            jQuery('html, body').animate({

                scrollTop: jQuery(tergrtVal).offset().top-85

            }, 1000);

            return false;

        });

        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
